import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export type InlineMessageVariation = 'info' | 'success' | 'info-dark';

@Component({
	selector: 'csd-app-inline-message',
	standalone: true,
	imports: [MatIconModule, NgClass],
	templateUrl: './inline-message.component.html',
	styleUrl: './inline-message.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineMessageComponent {
	variation = input<InlineMessageVariation>('info');
	icon = input('');
	classList = input('');

	componentCssClasses = computed(() => {
		return [`inline-message--${this.variation()}`, ...this.classList().split(' ')];
	});
}
