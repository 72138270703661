import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { Customer } from '../customer/customer.types';
import { CustomersState } from './customers.types';

const customerInitialState: CustomersState = {
	customers: {},
	customersList: [],
	selectedCustomerId: '',
	totalCustomers: 0,
};

export const CustomersStore = signalStore(
	{ providedIn: 'root' },
	withState(customerInitialState),
	withComputed((store) => ({
		selectedCustomer: computed(
			() => store.customers()[store.selectedCustomerId()] as Customer | undefined,
		),
	})),
	withMethods((store) => ({
		getCustomer(customerId: string) {
			return store.customers()[customerId];
		},
		addCustomerDetail(customer: Customer) {
			patchState(store, (state) => ({
				...state,
				customers: { ...state.customers, [customer.code]: customer },
			}));
		},
		updateCustomerDetail(customerId: string, customer: Partial<Customer>) {
			patchState(store, (state) => ({
				...state,
				customers: {
					...state.customers,
					[customerId]: { ...state.customers[customerId], ...customer },
				},
			}));
		},
		updateSelectedCustomer(customer: string) {
			patchState(store, (state) => ({ ...state, selectedCustomerId: customer }));
		},
	})),
);
